export function useDragScroll(elem: Ref<HTMLElement | undefined>) {
  const state = reactive({
    left: 0,
    x: 0,
  });

  const mouseDownHandler = (e: MouseEvent) => {
    if (!elem.value) return;

    elem.value.style.cursor = 'grabbing';
    elem.value.style.userSelect = 'none';

    state.left = elem.value.scrollLeft;
    state.x = e.clientX;

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = (e: MouseEvent) => {
    if (!elem.value) return;

    const dx = e.clientX - state.x;
    elem.value.scrollLeft = state.left - dx;
  };

  const mouseUpHandler = () => {
    if (!elem.value) return;

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    elem.value.style.cursor = 'grab';
    elem.value.style.removeProperty('user-select');
  };

  watch(elem, (value) => {
    if (!value) return;
    value.addEventListener('mousedown', mouseDownHandler);
  });
}
